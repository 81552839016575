import http from "../plugins/http";
import store from "../store";

export default [
    {
        path: "*",
        redirect: {
            path: "/404"
        }
    },
    {
        path: "/404",
        meta: {public: true},
        name: "NotFound",
        component: () => import(`@/views/error/NotFound.vue`)
    },
    {
        path: "/403",
        meta: {public: true},
        name: "AccessDenied",
        component: () => import(`@/views/error/Deny.vue`)
    },
    {
        path: "/500",
        meta: {public: true},
        name: "ServerError",
        component: () => import(`@/views/error/Error.vue`)
    },
    {
        path: "/",
        meta: {login: true},
        name: "Login",
        component: () => import(`@/views/Login.vue`)
    },
    // {
    //     path: "/login",
    //     meta: {},
    //     name: "Root",
    //     redirect: {
    //         name: "Login"
    //     }
    // },
    {
        path: "/dashboard",
        meta: {personal: true},
        name: "dashboard",
        component: () => import(`@/views/Dashboard.vue`)
    },
    {
        path: "/reports",
        meta: {personal: true},
        name: "reports",
        component: () => import(`@/views/ReportsDict/Reports.vue`)
    },
    {
        path: "/reports/create",
        meta: {personal: true},
        name: "reports-create",
        component: () => import(`@/views/ReportsDict/CreateReport.vue`)
    },
    {
        path: "/reports/edit/:id",
        meta: {personal: true},
        name: "reports-edit",
        component: () => import(`@/views/ReportsDict/CreateReport.vue`)
    },
    {
        path: "/report/:id",
        meta: {personal: true},
        name: "report",
        component: () => import(`@/views/ReportsDict/Report.vue`)
    },
    {
        path: "/videos",
        meta: {personal: true},
        name: "videos",
        component: () => import(`@/views/VideoDict/Videos.vue`)
    },
    {
        path: "/video/create",
        meta: {personal: true},
        name: "video-create",
        component: () => import(`@/views/VideoDict/CreateVideo.vue`)
    },
    {
        path: "/video/edit/:id",
        meta: {personal: true},
        name: "video-edit",
        component: () => import(`@/views/VideoDict/CreateVideo.vue`)
    },
    {
        path: "/video/preview/:id",
        meta: {personal: true},
        name: "video",
        component: () => import(`@/views/VideoDict/Video.vue`)
    },
    {
        path: "/establishments",
        meta: {personal: true},
        name: "establishments",
        component: () => import(`@/views/EstablishmentsDict/Establishments.vue`)
    },
    {
        path: "/establishments/create",
        meta: {personal: true},
        name: "establishments-create",
        component: () => import(`@/views/EstablishmentsDict/CreateEstablishment.vue`)
    },
    {
        path: "/establishments/edit/:id",
        meta: {personal: true},
        name: "establishments-edit",
        component: () => import(`@/views/EstablishmentsDict/CreateEstablishment.vue`)
    },
    {
        path: "/establishment/preview/:id",
        meta: {preview: true},
        name: "establishment-preview",
        component: () => import(`@/views/EstablishmentsDict/EstablishmentPreview.vue`)
    },
    {
        path: "/news/preview/:id",
        meta: {personal: true},
        name: "news-preview",
        component: () => import(`@/views/NewsDict/NewsPreview.vue`)
    },
    {
        path: "/news",
        meta: {personal: true},
        name: "all-news",
        component: () => import(`@/views/NewsDict/AllNews.vue`)
    },
    {
        path: "/news/create",
        meta: {personal: true},
        name: "news-create",
        component: () => import(`@/views/NewsDict/CreateNews.vue`)
    },
    {
        path: "/news/edit/:id",
        meta: {personal: true},
        name: "news-edit",
        component: () => import(`@/views/NewsDict/CreateNews.vue`)
    },
    {
        path: "/advertising",
        meta: {personal: true},
        name: "advertising",
        component: () => import(`@/views/AdvertisingDict/Advertising.vue`)
    },
    // {
    //     path: "/advertising/preview/:id",
    //     meta: {personal: true},
    //     name: "advertising",
    //     component: () => import(`@/views/AdvertisingDict/Posters.vue`)
    // },
    {
        path: "/advertising/create",
        meta: {personal: true},
        name: "advertising-create",
        component: () => import(`@/views/AdvertisingDict/CreateAdvertising.vue`)
    },
    {
        path: "/advertising/edit/:id",
        meta: {personal: true},
        name: "advertising-edit",
        component: () => import(`@/views/AdvertisingDict/CreateAdvertising.vue`)
    },
    {
        path: "/posters",
        meta: {personal: true},
        name: "posters",
        component: () => import(`@/views/PostersDict/Posters.vue`)
    },
    // {
    //     path: "/poster/preview/:id",
    //     meta: {personal: true},
    //     name: "advertising",
    //     component: () => import(`@/views/AdvertisingDict/Posters.vue`)
    // },
    {
        path: "/poster/create",
        meta: {personal: true},
        name: "posters-create",
        component: () => import(`@/views/PostersDict/CreatePoster.vue`)
    },
    {
        path: "/poster/edit/:id",
        meta: {personal: true},
        name: "posters-edit",
        component: () => import(`@/views/PostersDict/CreatePoster.vue`)
    },
    {
        path: "/contacts",
        meta: {personal: true},
        name: "contacts",
        component: () => import(`@/views/ContactsDict/Contacts.vue`)
    },
    {
        path: "/settings",
        meta: {personal: true},
        name: "settings",
        component: () => import(`@/views/Settings.vue`)
    },
    {
        path: "/users",
        meta: {personal: true},
        name: "users",
        component: () => import(`@/views/UsersDict/Users.vue`)
    },
    {
        path: "/users/create",
        meta: {personal: true},
        name: "users-create",
        component: () => import(`@/views/UsersDict/CreateUser.vue`)
    },
    {
        path: "/users/edit/:id",
        meta: {personal: true},
        name: "users-edite",
        component: () => import(`@/views/UsersDict/CreateUser.vue`)
    },
    {
        path: "/roles",
        meta: {personal: true},
        name: "roles",
        component: () => import(`@/views/RolesDict/Roles.vue`)
    },
    {
        path: "/roles/create",
        meta: {personal: true},
        name: "roles-create",
        component: () => import(`@/views/RolesDict/CreateRole.vue`)
    },
    {
        path: "/roles/edit/:id",
        meta: {personal: true},
        name: "roles-edit",
        component: () => import(`@/views/RolesDict/CreateRole.vue`)
    },
    {
        path: "/account",
        meta: {personal: true},
        name: "account",
        component: () => import(`@/views/AccountDict/Account.vue`)
    },
    {
        path: "/languages",
        meta: {personal: true},
        name: "languages",
        component: () => import(`@/views/LangDict/Lang.vue`)
    },
    {
        path: "/language/create",
        meta: {personal: true},
        name: "language-create",
        component: () => import(`@/views/LangDict/CreateLang.vue`)
    },
    {
        path: "/cities",
        meta: {personal: true},
        name: "cities",
        component: () => import(`@/views/CityDict/Cities.vue`)
    },
    {
        path: "/city/create",
        meta: {personal: true},
        name: "city-create",
        component: () => import(`@/views/CityDict/CreateCity.vue`)
    },
    {
        path: "/photographers",
        meta: {personal: true},
        name: "photographers",
        component: () => import(`@/views/PhotographersDict/Photographers.vue`)
    },
    {
        path: "/photographer/:id",
        meta: {personal: true},
        name: "photographer",
        component: () => import(`@/views/PhotographersDict/Photographer.vue`)
    },
    {
        path: "/photographers/create",
        meta: {personal: true},
        name: "photographer-create",
        component: () => import(`@/views/PhotographersDict/CreatePhotographer.vue`)
    },
    {
        path: "/photographer/edit/:id",
        meta: {personal: true},
        name: "photographer-edit",
        component: () => import(`@/views/PhotographersDict/CreatePhotographer.vue`)
    },
    {
        path: "/category/kitchen",
        meta: {personal: true},
        name: "kitchen",
        component: () => import(`@/views/CategoriesDict/Kitchen.vue`)
    },
    {
        path: "/category/create/kitchen",
        meta: {personal: true},
        name: "create-kitchen",
        component: () => import(`@/views/CategoriesDict/CreateKitchen.vue`)
    },
    {
        path: "/category/establishment",
        meta: {personal: true},
        name: "establishment",
        component: () => import(`@/views/CategoriesDict/Establishment.vue`)
    },
    {
        path: "/category/create/establishment",
        meta: {personal: true},
        name: "create-establishment",
        component: () => import(`@/views/CategoriesDict/CreateEstab.vue`)
    },
    {
        path: "/category/events",
        meta: {personal: true},
        name: "events",
        component: () => import(`@/views/CategoriesDict/Events.vue`)
    },
    {
        path: "/category/create/event",
        meta: {personal: true},
        name: "create-event",
        component: () => import(`@/views/CategoriesDict/CreateEvent.vue`)
    },
    {
        path: "/urls",
        meta: {personal: true},
        name: "urls",
        component: () => import(`@/views/url/Urls.vue`)
    },
    {
        path: "/robots",
        meta: {personal: true},
        name: "robots",
        component: () => import(`@/views/robots.txt/RobotsTxt.vue`)
    },
    {
        path: "/redirects",
        meta: {personal: true},
        name: "redirects",
        component: () => import(`@/views/Redirect/Redirects.vue`)
    },
    {
        path: "/redirects/create",
        meta: {personal: true},
        name: "redirect-create",
        component: () => import(`@/views/Redirect/CreateRedirect.vue`)
    },
    {
        path: "/redirects/edit/:id",
        meta: {personal: true},
        name: "redirect-edit",
        component: () => import(`@/views/Redirect/CreateRedirect.vue`)
    },
    {
        path: "/feedback",
        meta: {personal: true},
        name: "redirect-edit",
        component: () => import(`@/views/Feedback/Feedbacks.vue`)
    },
    {
        path: "/booking",
        meta: {personal: true},
        name: "redirect-edit",
        component: () => import(`@/views/Booking/Bookings.vue`)
    },

    {
        path: "/pages",
        meta: {personal: true},
        name: "pages",
        component: () => import(`@/views/Pages/Pages.vue`)
    },
    {
        path: "/pages/create",
        meta: {personal: true},
        name: "pages-create",
        component: () => import(`@/views/Pages/CreatePage.vue`)
    },
    {
        path: "/pages/edit/:id",
        meta: {personal: true},
        name: "pages-edit",
        component: () => import(`@/views/Pages/CreatePage.vue`)
    },

    // {
    //     path: "/signup",
    //     meta: {login: true},
    //     name: "Signup",
    //     component: () => import(`@/views/Authorization/Register.vue`)
    // },

];
